import React from "react";

import "./grid.scss";

export function Grid({children, columns}) {

    return (
        <div className={`grid--${columns}`}>
            {children.map((child, index) => 
                <div key={index} className="grid__item">{child}</div>
            )}
        </div>
    ) 
}