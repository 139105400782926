import React from "react";
import { Title } from "../title/title";
import { Menu } from "../menu/menu";

import './header.scss';
import { useMediaQuery } from "react-responsive";

export function Header() {

    const isMobile = useMediaQuery({maxWidth: '640px'});

    return (
        <div className='header'>
            <Title />
            <Menu isMobile={isMobile} />
        </div>
    )
}