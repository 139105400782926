import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { Header } from './modules/header/header';
import { Main } from './modules/main/main';


function App() {

  return (
    <Router basename="/">
      <div className='app'>
        <Header />
        <Main />
      </div>
    </Router>
  );
}

export default App;
