import React from "react";
import { useMediaQuery } from "react-responsive";
import { BOOKS } from "../../constants/books";

import './bookDetailPage.scss';

export function BookDetailPage({book}) {
    const bookDetails = BOOKS[book];
    const isMobile = useMediaQuery({maxWidth: "640px"})

    if (isMobile) {
        return (
            <div className="book-detail-page--mobile">
                <div className="book-detail-page--mobile__description">
                    <div className="book-detail-page--mobile__title">{bookDetails.title}</div>
                    <div className="book-detail-page--mobile__about">{bookDetails.about}</div>
                </div>
                <div className="book-detail-page--mobile__images">
                    {bookDetails.images.map((image, index) => 
                        <img src={image} key={index} className="book-detail-page--mobile__image"/>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className="book-detail-page">
                <div className="book-detail-page__images">
                    {bookDetails.images.map((image, index) => 
                        <img src={image} key={index} className="book-detail-page__image"/>
                    )}
                </div>
                <div className="book-detail-page__description">
                    <div className="book-detail-page__sticky">
                        <div className="book-detail-page__title">{bookDetails.title}</div>
                        <div className="book-detail-page__about">{bookDetails.about}</div>
                    </div>
                </div>

        </div>
    )
}