import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Grid } from "../grid/grid";
import { CONCEPTS } from "../../constants/concepts";

import './concepts.scss';

export function Concepts() {

    const isMobile = useMediaQuery({maxWidth: "640px"});

    if (isMobile) {
        return (
            Object.keys(CONCEPTS).map((key, index) => {
                const concept = CONCEPTS[key];
                return (
                    <Link to={`/concepts/${concept.url}`}>
                        <div className="concept--mobile" key={index}>
                            <img src={concept.images[0]} className="concept__image--mobile"/>
                            <div className="concept__title">{concept.title}</div>
                        </div>
                    </Link>
                )
            })
        )
    }

    return (
        <Grid columns={2}>
            {Object.keys(CONCEPTS).map((key, index) => {
                const concept = CONCEPTS[key];
                return (
                    <Link to={`/concepts/${concept.url}`}>
                        <div className="concept" key={index}>
                            <img src={concept.images[0]} className="concept__image"/>
                            <div className="concept__title">{concept.title}</div>
                        </div>
                    </Link>
                )
            })}
        </Grid>
    )
}