import React, { useState } from "react";
import { Tab } from "../tabs/tab";

import "./dropdown.scss";

export function DropdownMenu({label, dropdownOptions, activeTab, setActiveTab}) {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        if (isDropdownOpen) {
            setIsDropdownOpen(false);
            return;
        }

        setIsDropdownOpen(true);
        return;
    } 
    return (
        <div className="dropdown-menu">
            <div className="dropdown-menu__trigger" onClick={toggleDropdown}>
                <div className="dropdown-menu__label">{label}</div>
            </div>
            <div className={`dropdown-menu__options${isDropdownOpen ? ' dropdown-menu__options--open' : ''}`}>
                {dropdownOptions.map((option) => 
                    <>
                        <div className="dropdown-menu__option" onClick={toggleDropdown}>
                            <Tab
                                url={option.url} 
                                id={option.id}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                label={option.label}
                            />
                        </div>
                        <div className="dropdown-menu__divider" />
                    </>
                )}
            </div>
        </div>
    )
}