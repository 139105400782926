const charming = require.context('../assets/charming', true);
const charmingImageList = charming.keys().map(image => charming(image));

const more = require.context('../assets/more', true);
const moreImageList = more.keys().map(image => more(image));

export const CONCEPTS = {
    charming: {
        title: 'Charming',
        about: <>
                <p><i>"You, a Princess, are betrothed to the last Prince of a dying kingdom. A month before your wedding your kingdom sends you to his palace, to settle in and get familiar with your surroundings. Interestingly enough, your surroundings are crowded with Charming Maidens. The clueless Prince? Forget him. These Charming Maidens are far more intriguing."</i></p>
                <p>Avert a patriarchal dark ages by replacing the existing Royal Family with your own lesbian dynasty by matchmaking, flirting and seducing towards your own happily ever after.</p>
                <p>Game concept for a Royal lesbian dating simulator.</p>
            </>,
        cover: charmingImageList[0],
        images: charmingImageList,
        url: 'charming'
    },
    more: {
        title: 'More',
        about: <>
                <p>It's Fig's birthday today, and to celebrate they buy themselves a box of chocolate to eat at home. The journey turns out to be eventful.</p>
                <p>Screenshots from <b>More</b>, an interactive comic I worked on as a writer and illustrator.</p>
            </>,
        cover: moreImageList[0],
        images: moreImageList,
        url: 'more'
    }
};