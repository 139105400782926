export const MENU_OPTIONS = [
    {
        id: 'gallery',
        label: 'gallery',
        url: '/gallery'
    },
    {
        id: 'picture-books',
        label: 'picture-books',
        url: '/picture-books'
    },
    {
        id: 'concepts',
        label: 'concepts',
        url: '/concepts'
    },
    {
        id: 'about',
        label: 'about',
        url: '/about'
    },
]