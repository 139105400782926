const vampireCrocs = require.context('../assets/vampire_crocs', true);
const vampireCrocsImageList = vampireCrocs.keys().map(image => vampireCrocs(image));

const dragon = require.context('../assets/see_the_dragon', true);
const dragonImageList = dragon.keys().map(image => dragon(image));

export const BOOKS = {
    vampire_crocs: {
        title: 'Vampire Crocodiles: The Baby',
        about: <>
                <p>"There are no Vampire Crocodiles in the world"  That's at least what most people would say.</p>
                <p>But I'll tell you a secret: In a mansion in the swamp there are actually four of them!</p>
                <p>Today, one of those vampire crocodiles is about to discover something new.</p>
                <p><b>Vampire Crocodiles: The Baby</b> is a picture book perfect for all quirky kids who are learning to read on their own.  A 64 page adventure full of heartfelt jokes and silly love!</p>
            </>,
        cover: vampireCrocsImageList[0],
        images: vampireCrocsImageList,
        url: 'vampire-crocodiles'
    },
    see_the_dragon: {
        title: 'See The Dragon?',
        about: <>
                <p>“The dragon tried to EAT me!” the baker yelled at the Mayor who stood blearily on the doorstep of her house. She was wearing her morning robe with a visible stain of coffee on her left sleeve.</p>
                <p>“That doesn’t sound right,”</p>
                <p>said the Mayor after a moment of silence.</p>
                <p>“Our dragon doesn’t eat <b>villagers!</b>"</p>
            </>,
        cover: dragonImageList[0],
        images: dragonImageList,
        url: 'see-the-dragon'
    }
};