import React from "react";
import { useMediaQuery } from "react-responsive";
import { CONCEPTS } from "../../constants/concepts";

import './conceptDetailPage.scss';

export function ConceptDetailPage({concept}) {
    const conceptDetails = CONCEPTS[concept];
    const isMobile = useMediaQuery({maxWidth: "640px"})

    if (isMobile) {
        return (
            <div className="concept-detail-page--mobile">
                <div className="concept-detail-page--mobile__description">
                    <div className="concept-detail-page--mobile__title">{conceptDetails.title}</div>
                    <div className="concept-detail-page--mobile__about">{conceptDetails.about}</div>
                </div>
                <div className="concept-detail-page--mobile__images">
                    {conceptDetails.images.map((image, index) => 
                        <img src={image} key={index} className="concept-detail-page--mobile__image"/>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className="concept-detail-page">
                <div className="concept-detail-page__images">
                    {conceptDetails.images.map((image, index) => 
                        <img src={image} key={index} className="concept-detail-page__image"/>
                    )}
                </div>
                <div className="concept-detail-page__description">
                    <div className="concept-detail-page__sticky">
                        <div className="concept-detail-page__title">{conceptDetails.title}</div>
                        <div className="concept-detail-page__about">{conceptDetails.about}</div>
                    </div>
                </div>

        </div>
    )
}