import React from "react";
import { Link } from "react-router-dom";

import './tab.scss';

export function Tab({url, id, activeTab, setActiveTab, label}) {
    const isActive = id === activeTab;

    const handleClick = () => {
        setActiveTab(id);
        window.scrollTo(0,0);
    }

    return (
        <Link onClick={()=>handleClick()} className={`tab${isActive ? ' tab--active' : ''}`} to={url}>{label}
        </Link>
    )
}