import React, { useEffect, useState } from "react";

import './menu.scss'
import { Tab } from "../tabs/tab";
import { DropdownMenu } from "../dropdown/dropdown";
import { MENU_OPTIONS } from "../../constants/menuOptions";

export function Menu({isMobile}) {

    const [activeTab, setActiveTab] = useState(null);

    useEffect(() => {
        const currentUrl = window.location.href;
        const defaultTab = currentUrl.split('/')[3];
        setActiveTab(defaultTab)
    }, [])

    if (isMobile) {
        return (
            <DropdownMenu label="menu" dropdownOptions={MENU_OPTIONS} activeTab={activeTab} setActiveTab={setActiveTab} />
        )
    }

    return (
        <div className='menu'>
            <ul className='menu__list'>
                <Tab url="/gallery" id="gallery" activeTab={activeTab} setActiveTab={setActiveTab} label="gallery"/>
                <Tab url="/picture-books" id="picture-books" activeTab={activeTab} setActiveTab={setActiveTab} label="picture books"/>
                <Tab url="/concepts" id="concepts" activeTab={activeTab} setActiveTab={setActiveTab} label="concepts"/>
                <Tab url="/about" id="about" activeTab={activeTab} setActiveTab={setActiveTab} label="about"/>
            </ul>
        </div>
    )
}