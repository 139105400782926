import React from "react"
import {
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import { Gallery } from "../gallery/gallery";
import { Books } from "../books/books";
import { About } from "../about/about";
import { BOOKS } from "../../constants/books";
import { Concepts } from "../concepts/concepts";
import { CONCEPTS } from "../../constants/concepts";
import { BookDetailPage } from "../books/bookDetailPage";
import { ConceptDetailPage } from "../concepts/conceptDetailPage";

import './main.scss';

export function Main() {

    return (
        <div className="main">
            <Routes>
                <Route exact path='/' element={<Navigate to='/gallery'/>} />
                <Route exact path='/gallery' element={<Gallery/>} />
                <Route exact path='/picture-books' element={<Books/>} />
                <Route exact path='/concepts' element={<Concepts/>} />
                <Route exact path='/about' element={<About/>} />
                {Object.keys(BOOKS).map(key => {
                    const book = BOOKS[key];
                    return (
                        <Route exact path={`/picture-books/${book.url}`} element={<BookDetailPage book={key}/>} />
                    )
                })}
                {Object.keys(CONCEPTS).map(key => {
                    const concept = CONCEPTS[key];
                    return (
                        <Route exact path={`/concepts/${concept.url}`} element={<ConceptDetailPage concept={key}/>} />
                    )
                })}
            </Routes>
        </div>
    )
}