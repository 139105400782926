import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Grid } from "../grid/grid";
import { BOOKS } from "../../constants/books";

import './books.scss';

export function Books() {

    const isMobile = useMediaQuery({maxWidth: "640px"});

    if (isMobile) {
        return (
            Object.keys(BOOKS).map((key, index) => {
                const book = BOOKS[key];
                return (
                    <Link to={`/picture-books/${book.url}`}>
                        <div className="book--mobile" key={index}>
                            <img src={book.images[0]} className="book__image--mobile"/>
                            <div className="book__title">{book.title}</div>
                        </div>
                    </Link>
                )
            })
        )
    }

    return (
        <Grid columns={2}>
            {Object.keys(BOOKS).map((key, index) => {
                const book = BOOKS[key];
                return (
                    <Link to={`/picture-books/${book.url}`}>
                        <div className="book" key={index}>
                            <img src={book.images[0]} className="book__image"/>
                            <div className="book__title">{book.title}</div>
                        </div>
                    </Link>
                )
            })}
        </Grid>
    )
}