import React, {useState} from "react";
import { useMediaQuery } from "react-responsive";
import { Grid } from "../grid/grid";
import { Modal } from "../modal/modal";

import './gallery.scss';

export function Gallery() {
    const isMobile = useMediaQuery({maxWidth: '640px'});
    const images = require.context('../../assets/gallery', true);
    const imageList = images.keys().map(image => images(image));

    const [modalContent, setModalContent] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = (image) => {
        setModalContent(image);
        setIsOpen(true);
    }

    if (isMobile) {
        return (
            <div className="gallery--mobile">
                {imageList.map((image, index) => 
                    <div onClick={() => handleOpenModal(image)} className="gallery--mobile__image-container">
                        <img key={index} src={image} className="gallery--mobile__image"/>
                    </div>
                )}
                <Modal id="galleryModal" open={isOpen} onOutsideClick={() => setIsOpen(false)}>
                    <img src={modalContent} className="gallery__modal-content"/>
                </Modal>
            </div>
        )
    }
    
    return (
        <div className="gallery">
            <Grid columns={3}>
                {imageList.map((image, index) => 
                    <div onClick={() => handleOpenModal(image)} className="gallery__image-container">
                        <img key={index} src={image} className="gallery__image"/>
                    </div>
                )}
            </Grid>
            <Modal id="galleryModal" open={isOpen} onOutsideClick={() => setIsOpen(false)}>
                <img src={modalContent} className="gallery__modal-content"/>
            </Modal>
        </div>
    )
}