import React from "react";

import './modal.scss';

export function Modal({id, open, onOutsideClick, children}) {
    
    if (open) {
        return (
            <>
                <dialog open id={id} className="modal">
                    {children}
                </dialog>
                <div className="modal-overlay" onClick={onOutsideClick} />
            </>
        );
    }
    
    return;
}