import React from "react";
import astrid from "../../assets/images/astrid.png"

import './about.scss';
import { DESCRIPTION, QUOTE } from "../../constants/about";
import { useMediaQuery } from "react-responsive";

export function About() {

    const isMobile = useMediaQuery({maxWidth: "640px"});

    if (isMobile) {
        return (
            <>
                <div className="about--mobile">
                <div className="about--mobile__quote">{QUOTE}</div>
                    <div className="about--mobile__image-container">
                        <img src={astrid} className="about--mobile__image"/>
                    </div>
                    <div className="about--mobile__text">
                        <div className="about--mobile__description">{DESCRIPTION}</div>
                    </div>
                </div>
                <div className="contact">
                    <div className="contact__icons">
                        <a href="https://www.instagram.com/astrid.stefans/" target="_blank" className="contact__icon">
                            <i className="fa-brands fa-instagram fa-xl"/>
                        </a>
                        <a href="mailto:astridstefans@gmail.com" target="_blank" className="contact__icon">
                            <i class="fa-solid fa-square-envelope fa-xl"/>
                        </a>
                    </div>
                    <div className="contact__email">astridstefans@gmail.com</div>
                </div>
            </>
        )
    }

    return (
        <>
        <div className="about">
            <div className="about__image-container">
                <img src={astrid} className="about__image"/>
            </div>
            <div className="about__text">
                <div className="about__quote">{QUOTE}</div>
                <div className="about__description">{DESCRIPTION}</div>
            </div>
        </div>
        <div className="contact">
            <div className="contact__icons">
                <a href="https://www.instagram.com/astrid.stefans/" target="_blank" className="contact__icon">
                    <i className="fa-brands fa-instagram fa-xl"/>
                </a>
                <a href="mailto:astridstefans@gmail.com" target="_blank" className="contact__icon">
                    <i class="fa-solid fa-square-envelope fa-xl"/>
                </a>
            </div>
            <div className="contact__email">astridstefans@gmail.com</div>
        </div>
        </>
    )
}